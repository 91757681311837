import { ref, inject } from "vue";
import api from "@/services/userApi";

export default function useContacts() {
  const t = inject("t");
  const contact = ref({
    firstName: "",
    lastName: "",
    number: "",
    company: "",
    email: "",
    title: "",
  });
  const contacts = ref([]);
  const totalRecords = ref(0);
  const notify = inject("notify");

  const n = (label, type = "danger") => {
    notify({
      message: t(`${label}`),
      type,
    });
  };

  //   FIND CONTACTS
  const findContacts = async () => {
    try {
      const response = await api.get("/admin/xpbx/settings/phonebook");

      if (response?.data) contacts.value = response.data;
    } catch (error) {
      console.log(error);
    }
  };

  // FILTER CONTACTS
  const searchContacts = async (filter) => {
    try {
      const response = await api.post(
        "/admin/xpbx/settings/phonebook/search",
        filter
      );
      contacts.value = [];
      if (response?.data?.records?.length) {
        setTimeout(() => {
          contacts.value = response.data.records;
          totalRecords.value = response.data.total;
        }, 50);
      } else {
        totalRecords.value = 0;
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   FIND CONTACT
  const findContact = async (id) => {
    try {
      const response = await api.get(`/admin/xpbx/settings/phonebook/${id}`);

      if (response?.data?.length) {
        const contactResponse = response.data[0];

        contact.value = {
          id: contactResponse.id,
          firstName: contactResponse.first_name,
          lastName: contactResponse.last_name,
          number: contactResponse.number,
          company: contactResponse.company,
          email: contactResponse.email,
          title: contactResponse.title || "Mr.",
        };
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   CREATE CONTACT
  const createContact = async (data) => {
    try {
      const response = await api.post(`/admin/xpbx/settings/phonebook`, data);

      if (response?.data?.id) {
        const oldContacts = [...contacts.value];
        const newContacts = [...oldContacts, response.data];
        contacts.value = newContacts;
        n("notify.xpbx.create_contact", "success");
      } else {
        n("notify.xpbx.error_create_contact");
      }
    } catch (error) {
      n("notify.xpbx.error_create_contact");
    }
  };

  //   UPDATE CONTACT
  const updateContact = async (form, id) => {
    try {
      const response = await api.put(
        `/admin/xpbx/settings/phonebook/${id}`,
        form
      );

      if (response?.data?.id) {
        n("notify.xpbx.edit_contact", "success");

        const index = contacts.value.findIndex((i) => i.id === id);
        contacts.value[index] = response.data;
      } else {
        n("notify.xpbx.error_edit_contact");
      }
    } catch (error) {
      n("notify.xpbx.error_edit_contact");
    }
  };

  //   DELETE CONTACT
  const deleteContact = async (id) => {
    try {
      const response = await api.delete(`/admin/xpbx/settings/phonebook/${id}`);

      if (response.status === 200) {
        n("notify.xpbx.delete_extension", "success");

        contacts.value = contacts.value.filter((i) => i.id !== id);
      } else {
        n("notify.xpbx.error_delete_extension");
      }
      return response;
    } catch (error) {
      n("notify.xpbx.error_delete_extension");
    }
  };

  // DELETE CONTACTS
  const deleteContacts = async (ids) => {
    const deleted = [];
    for (const item of ids) {
      try {
        const response = await api.delete(
          `/admin/xpbx/settings/phonebook/${item?.id}`
        );
        if (response.status === 200) {
          deleted.push(item?.id);
        }
      } catch (error) {
        n("notify.xpbx.error_delete_extension");
      }
    }

    contacts.value = contacts.value.filter((i) => !deleted.includes(i.id));

    n("notify.xpbx.delete_extension", "success");
  };

  return {
    contact,
    contacts,
    totalRecords,
    findContact,
    findContacts,
    updateContact,
    createContact,
    deleteContact,
    deleteContacts,
    searchContacts,
  };
}
