<template>
  <!-- Contacts table -->
  <div class="bg-white w-full p-3">
    <section class="w-full h-full">
      <PageHeader
        :heading="$t('xpbx.pages.heading.contacts')"
        :showButton="false"
        :buttonTitle="$t('xpbx.button.create_contact')"
        @buttonClick="createContactHandler"
      />
      <!-- Contacts table -->
      <div class="card relative table-wrapper contact-wrapper">
        <DataTable
          ref="dt"
          dataKey="id"
          :value="contacts"
          selectionMode="single"
          :rows="10"
          :loading="loading"
          removableSort
          sortMode="multiple"
          scrollable
          :scrollHeight="`${scrollHeight}px`"
          v-model:selection="selectedRecords"
          v-model:filters="filters"
          filterDisplay="menu"
          :rowClass="rowClass"
          @sort="onSort($event)"
          @filter="onFilter($event)"
          @rowSelect="onRowSelect"
          @rowUnselect="onRowUnselect"
          :globalFilterFields="['first_name', 'last_name']"
        >
          <template #header>
            <div
              class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
            >
              <div>
                <Button
                  :label="$t('xpbx.button.new')"
                  icon="pi pi-plus"
                  severity="success"
                  class="mr-2 add-record-button"
                  @click="openNew"
                />
                <Button
                  :label="$t('xpbx.button.delete')"
                  icon="pi pi-trash"
                  severity="danger"
                  class="delete-button"
                  @click="confirmDeleteSelected"
                  :disabled="!selectedRecord?.id"
                />
              </div>
              <div class="flex gap-2 items-center ml-auto">
                <IconField iconPosition="left">
                  <InputIcon>
                    <i class="pi pi-search" />
                  </InputIcon>
                  <InputText
                    v-model="filters['global'].value"
                    :placeholder="$t('xpbx.placeholders.search')"
                  />
                </IconField>
                <Export
                  :dt="dt"
                  :tableData="contacts"
                  :columns="contactHeaders"
                />
              </div>
            </div>
            <div>
              <Paginator
                :rows="10"
                ref="paginator"
                @page="onPage($event)"
                :totalRecords="totalRecords"
                template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                :rowsPerPageOptions="[10, 20, 30]"
              ></Paginator>
            </div>
          </template>
          <Column
            field="first_name"
            sortable
            :header="$t('xpbx.table-field.first_name')"
          >
            <template #body="{ data }">
              {{ data?.first_name }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter table-filter"
                :placeholder="$t('xpbx.table-field.first_name')"
              />
            </template>
            <template #filterclear="{ filterCallback }">
              <Button
                type="button"
                @click="clearFilters('first_name', filterCallback)"
                severity="secondary"
                >{{ $t("xpbx.button.clear") }}</Button
              >
            </template>
            <template #filterapply="{ filterCallback }">
              <Button
                type="button"
                @click="filterSearch(filterCallback)"
                severity="success"
                >{{ $t("xpbx.button.apply") }}</Button
              >
            </template>
          </Column>
          <Column
            field="last_name"
            sortable
            :header="$t('xpbx.table-field.last_name')"
          >
            <template #body="{ data }">
              {{ data?.last_name }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter table-filter"
                :placeholder="$t('xpbx.table-field.first_name')"
              />
            </template>
            <template #filterclear="{ filterCallback }">
              <Button
                type="button"
                @click="clearFilters('last_name', filterCallback)"
                severity="secondary"
                >{{ $t("xpbx.button.clear") }}</Button
              >
            </template>
            <template #filterapply="{ filterCallback }">
              <Button
                type="button"
                @click="filterSearch(filterCallback)"
                severity="success"
                >{{ $t("xpbx.button.apply") }}</Button
              >
            </template>
          </Column>
          <Column
            field="number"
            sortable
            :header="$t('xpbx.table-field.phone_number')"
          >
            <template #body="{ data }">
              {{ data?.number }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter table-filter"
                :placeholder="$t('xpbx.table-field.first_name')"
              />
            </template>
            <template #filterclear="{ filterCallback }">
              <Button
                type="button"
                @click="clearFilters('phone_number', filterCallback)"
                severity="secondary"
                >{{ $t("xpbx.button.clear") }}</Button
              >
            </template>
            <template #filterapply="{ filterCallback }">
              <Button
                type="button"
                @click="filterSearch(filterCallback)"
                severity="success"
                >{{ $t("xpbx.button.apply") }}</Button
              >
            </template>
          </Column>
          <Column
            field="company"
            sortable
            :header="$t('xpbx.table-field.company')"
          >
            <template #body="{ data }">
              {{ data?.company }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter table-filter"
                :placeholder="$t('xpbx.table-field.company')"
              />
            </template>
            <template #filterclear="{ filterCallback }">
              <Button
                type="button"
                @click="clearFilters('company', filterCallback)"
                severity="secondary"
                >{{ $t("xpbx.button.clear") }}</Button
              >
            </template>
            <template #filterapply="{ filterCallback }">
              <Button
                type="button"
                @click="filterSearch(filterCallback)"
                severity="success"
                >{{ $t("xpbx.button.apply") }}</Button
              >
            </template>
          </Column>
          <Column field="email" sortable :header="$t('xpbx.table-field.email')">
            <template #body="{ data }">
              {{ data?.email }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter table-filter"
                :placeholder="$t('xpbx.table-field.email')"
              />
            </template>
            <template #filterclear="{ filterCallback }">
              <Button
                type="button"
                @click="clearFilters('email', filterCallback)"
                severity="secondary"
                >{{ $t("xpbx.button.clear") }}</Button
              >
            </template>
            <template #filterapply="{ filterCallback }">
              <Button
                type="button"
                @click="filterSearch(filterCallback)"
                severity="success"
                >{{ $t("xpbx.button.apply") }}</Button
              >
            </template>
          </Column>
        </DataTable>
        <!-- <Loader v-else /> -->
      </div>

      <Dialog
        v-model:visible="deleteRecordsDialog"
        :style="{ width: '450px' }"
        header="Confirm"
        :modal="true"
      >
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span
            v-if="selectedRecord?.first_name || selectedRecord?.last_name"
            >{{
              $t("notify.xpbx.message.confirm_delete", {
                delete: `contact ${selectedRecord?.first_name} ${selectedRecord?.last_name}`,
              })
            }}</span
          >
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            text
            @click="deleteRecordsDialog = false"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            text
            @click="deleteSelectedRecords"
          />
        </template>
      </Dialog>

      <!-- Create contact -->
      <Dialog
        v-model:visible="contactDialog"
        :style="{ width: '450px' }"
        :dismissableMask="true"
        :header="
          isEdit
            ? $t('xpbx.pages.heading.edit_contact')
            : $t('xpbx.pages.heading.create_contact')
        "
        :modal="true"
        class="p-fluid relative custom-dialog-heading"
      >
        <!-- Title -->
        <div class="field mb-1 relative">
          <label for="password">{{ $t("xpbx.labels.title") }}</label>
          <form-select
            v-model="contact.title"
            :options="titles"
            name="channel"
            id="channel"
            :errors="contact.title"
            :activeClass="true"
          />
        </div>
        <QueueHint :title="$t('xpbx.hints.contacts.title')" />

        <!-- First name -->
        <div class="field mb-1">
          <label for="first_name">{{ $t("xpbx.labels.first_name") }}</label>
          <InputText
            id="firstName"
            v-model.trim="contact.firstName"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !contact.firstName }"
          />
          <small class="p-error" v-if="!!errors?.firstName?.[0]">{{
            errors?.firstName?.[0]
          }}</small>
        </div>
        <QueueHint :title="$t('xpbx.hints.contacts.firstName')" />

        <!-- Last name -->
        <div class="field mb-1">
          <label for="lastName">{{ $t("xpbx.labels.last_name") }}</label>
          <InputText
            id="lastName"
            v-model.trim="contact.lastName"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !contact.lastName }"
          />
          <small class="p-error" v-if="!!errors?.lastName?.[0]">{{
            errors?.lastName?.[0]
          }}</small>
        </div>
        <QueueHint :title="$t('xpbx.hints.contacts.lastName')" />

        <!-- Phone -->
        <div class="field mb-1">
          <label for="number">{{ $t("xpbx.labels.phone") }}</label>
          <InputText
            id="number"
            v-model.trim="contact.number"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !contact.number }"
          />
          <small class="p-error" v-if="!!errors?.number?.[0]">{{
            errors?.number?.[0]
          }}</small>
        </div>
        <QueueHint :title="$t('xpbx.hints.contacts.number')" />

        <!-- Company -->
        <div class="field mb-1">
          <label for="company">{{ $t("xpbx.labels.company") }}</label>
          <InputText
            id="company"
            v-model.trim="contact.company"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !contact.company }"
          />
          <small class="p-error" v-if="!!errors?.company?.[0]">{{
            errors?.company?.[0]
          }}</small>
        </div>
        <QueueHint :title="$t('xpbx.hints.contacts.company')" />

        <!-- Company -->
        <div class="field mb-1">
          <label for="email">{{ $t("xpbx.labels.email") }}</label>
          <InputText
            id="email"
            v-model.trim="contact.email"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !contact.email }"
          />
          <small class="p-error" v-if="!!errors?.email?.[0]">{{
            errors?.email?.[0]
          }}</small>
        </div>
        <QueueHint :title="$t('xpbx.hints.contacts.email')" />

        <template #footer>
          <Button label="Cancel" icon="pi pi-times" text @click="hideDialog" />
          <Button label="Save" icon="pi pi-check" text @click="create" />
        </template>
      </Dialog>
    </section>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import { useRouter } from "vue-router";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import TableSettings from "@/modules/xpbx/components/UI/table-settings";
import useContacts from "@/modules/xpbx/composables/useContacts";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
import validator from "@/composables/auth/validator";
import {
  validateCreateContact,
  validateEditContact,
} from "@/composables/auth/contactValidations";
// Datatable
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Paginator from "primevue/paginator";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import { FilterMatchMode } from "primevue/api";
import useTableFilters from "@/modules/xpbx/composables/useTableFilters";
import Export from "@/modules/xpbx/pages/settings/components/export/index.vue";
import { contactHeaders } from "@/modules/xpbx/pages/settings/components/table-headers/data.js";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/queue-hint/index.vue";

export default {
  name: "SettingContacts",

  components: {
    PageHeader,
    TableSettings,
    Loader,
    DataTable,
    Column,
    Button,
    InputText,
    Dialog,
    IconField,
    InputIcon,
    Paginator,
    Export,
    QueueHint,
  },

  setup() {
    const router = useRouter();
    const scrollHeight = ref(window.innerHeight - 290);
    const isEdit = ref(false);
    const submitted = ref(false);
    const paginator = ref(null);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const contact = ref({
      firstName: "",
      lastName: "",
      company: "",
      number: "",
      email: "",
      title: "Mr.",
    });
    // Datatable
    const dt = ref();
    const loading = ref(false);
    const { errors } = validator();
    const contactDialog = ref(false);
    const deleteRecordsDialog = ref(false);
    const lazyParams = ref({});
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      // first_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      first_name: {
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      last_name: {
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      number: {
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      company: {
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      email: {
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
    const { getFilters, resetFilters } = useTableFilters();

    const {
      totalRecords,
      deleteContact,
      contacts,
      createContact,
      updateContact,
      searchContacts,
    } = useContacts();

    const createContactHandler = () => {
      router.push({ name: "CreateContact" });
    };

    const hideDialog = () => {
      submitted.value = false;
      contactDialog.value = false;
      resetContact();
    };

    const create = async () => {
      const form = {
        first_name: contact.value?.firstName,
        last_name: contact.value?.lastName,
        number: contact.value?.number,
        company: contact.value?.company,
        email: contact.value?.email,
        title: contact.value?.title?.code || "Mr.",
      };

      if (isEdit.value === true) {
        const isValid = validateEditContact(contact.value);
        if (isValid) await updateContact(form, contact.value.id);
      } else {
        const isValid = validateCreateContact(contact.value);

        if (isValid) await createContact(form);
      }

      isEdit.value = false;
      contactDialog.value = false;
    };

    const resetContact = () => {
      contact.value = {
        firstName: "",
        lastName: "",
        company: "",
        number: "",
        email: "",
        title: "Mr.",
      };
      selectedRecord.value = null;
      isEdit.value = false;
    };

    // Data Table Methods
    const openNew = () => {
      resetContact();
      contact.value = {};
      contactDialog.value = true;
    };

    const onRowSelect = (event) => {
      isEdit.value = false;
      selectedRecord.value = event.data;
    };

    const onRowUnselect = (event) => {
      if (selectedRecord.value?.id === event.data.id) {
        const contactData = {
          ...event.data,
          firstName: event.data.first_name,
          lastName: event.data.last_name,
        };

        contact.value = contactData;
        isEdit.value = true;
        contactDialog.value = true;
      }
    };

    // FIlter methods
    const loadLazyData = async (filters) => {
      const currentPage = filters?.paging?.page || 1;
      paginator.value.currentPage = currentPage;
      loading.value = true;
      await searchContacts(filters);
      loading.value = false;
    };

    const onPage = async (event) => {
      const filters = getFilters(lazyParams.value, event);

      await loadLazyData(filters);
    };

    const onSort = async (event) => {
      lazyParams.value = event;

      const filters = getFilters(lazyParams.value);
      // Make API request
      await loadLazyData(filters);
    };

    const onFilter = async (event) => {
      lazyParams.value.filters = filters.value;
    };

    const filterSearch = async (fn) => {
      const filters = getFilters(lazyParams.value);
      // Make API request
      await loadLazyData(filters);
      fn();
    };

    const clearFilters = async (field, fn) => {
      const newFilters = resetFilters({ ...filters.value }, field);

      filters.value = newFilters;
      lazyParams.value.filters = newFilters;
      const filtersData = getFilters(lazyParams.value);

      // Make API request
      await loadLazyData(filtersData);
      fn();
    };

    const rowClass = (data) => {
      return [
        {
          "table-selected": isEdit.value && contact?.value?.id === data?.id,
        },
      ];
    };

    const closeDialog = (value) => {
      contactDialog.value = value;
    };

    const confirmDeleteSelected = () => {
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      await deleteContact(selectedRecord.value.id);
      deleteRecordsDialog.value = false;
    };

    const checkTableClick = () => {
      const appContainer = document.querySelector(".app-container");

      appContainer.addEventListener("click", (e) => {
        const classLength = e?.target?.classList?.length;
        const includeButton = e?.target?.classList?.contains("p-button-label");
        if (classLength > 0 && selectedRecord?.value?.id && !includeButton)
          selectedRecord.value = null;
      });
    };

    watch(contactDialog, (value) => {
      if (!value) resetContact();
    });

    onMounted(async () => {
      checkTableClick();
      // await findContacts();
      await searchContacts({
        filter: [],
        sort: [],
        paging: {
          page: 1,
          records: 10,
        },
      });
    });

    const titles = [
      { name: "Mr.", value: "Mr." },
      { name: "Mss.", value: "Mss." },
    ];

    return {
      dt,
      errors,
      titles,
      paginator,
      contact,
      submitted,
      isEdit,
      loading,
      contacts,
      filters,
      lazyParams,
      totalRecords,
      scrollHeight,
      contactHeaders,
      selectedRecord,
      selectedRecords,
      contactDialog,
      deleteRecordsDialog,

      onPage,
      onSort,
      onFilter,
      filterSearch,
      clearFilters,
      loadLazyData,
      getFilters,
      resetFilters,
      create,
      rowClass,
      hideDialog,
      resetContact,
      searchContacts,
      createContactHandler,
      openNew,
      closeDialog,
      onRowSelect,
      onRowUnselect,
      deleteSelectedRecords,
      confirmDeleteSelected,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";
</style>