import { computed, unref } from "vue";
import validator from "@/composables/auth/validator";

const hasErrors = computed(() => Object.entries(errors.value).length);
const { checkRequired, errors, clearErrors, isValidUrl } = validator();

export function validateCreateContact(data) {
  clearErrors();

  data = unref(data);

  checkRequired("firstName", data);
  checkRequired("lastName", data);
  checkRequired("company", data);
  checkRequired("number", data);
  checkRequired("email", data);

  return hasErrors.value ? false : true;
}

export function validateEditContact(data) {
  clearErrors();

  data = unref(data);

  checkRequired("firstName", data);
  checkRequired("lastName", data);
  checkRequired("number", data);

  return hasErrors.value ? false : true;
}
