import { ref, inject } from "vue";

export default function useTableFilters() {
  const filters = ref({
    search: "",
    sort: "",
    order: "",
    limit: 10,
    page: 1,
  });

  const getMatchValue = (match) => {
    switch (match) {
      case "contains":
        return "in";
      case "notContains":
        return "nin";
      case "startsWith":
        return "bw";
      case "endsWith":
        return "ew";
      case "equals":
        return "eq";
      case "dateIs":
        return "neq";
      case "notEquals":
        return "ne";
      case "dateIsNot":
        return "ne";
      case "in":
        return "in";
      case "en":
        return "en";
      case "cn":
        return "cn";
      case "ge":
        return "ge";
      case "bn":
        return "bn";
      case "gt":
        return "gt";
      case "lt":
        return "lt";
      case "le":
        return "le";
      case "ni":
        return "ni";
      case "gteq":
        return "gteq";
      case "lteq":
        return "lteq";
      case "gte":
        return "gte";
      case "lte":
        return "lte";
      case "between":
        return "btw";
      case "notBetween":
        return "nbw";
      case "isNull":
        return "isNull";
      case "isNotNull":
        return "isNotNull";
      default:
        return "contains";
    }
  };

  const checkDatesMatchValue = (constraints) => {
    const dateBefore = constraints.find((i) => i.matchMode === "dateBefore");

    const dateAfter = constraints.find((i) => i.matchMode === "dateAfter");

    if (dateBefore && dateAfter) return "btw";

    return constraints[0]?.matchMode;
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    month = +month > 9 ? month : `0${month}`;
    let day = date.getDate();
    day = +day > 9 ? day : `0${day}`;

    return `${year}-${month}-${day}`;
  };

  const formatDateWIthHours = (date) => {
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    month = +month > 9 ? month : `0${month}`;
    let day = date.getDate();
    day = +day > 9 ? day : `0${day}`;
    let hours = date.getHours();
    hours = +hours > 9 ? hours : `0${hours}`;
    let minutes = date.getMinutes();
    minutes = +minutes > 9 ? minutes : `0${minutes}`;
    let seconds = date.getSeconds();
    seconds = +seconds > 9 ? seconds : `0${seconds}`;

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const formatDates = (dates) => {
    const date1 = new Date(dates[0]);
    const date2 = new Date(dates[1]);

    const formattedDate1 = formatDateWIthHours(date1);
    const formattedDate2 = formatDateWIthHours(date2);

    return [formattedDate1, formattedDate2];
  };

  const getFilters = (lazyParams, pagination = {}) => {
    const filters = [];
    const sort = [];
    const paging = {
      page: 1,
      records: 10,
    };

    // Get filters search values
    for (const key in lazyParams.filters) {
      // console.log(key, lazyParams.filters[key]);
      const keyObject = lazyParams.filters[key];
      let field = key;
      if (key === "extension.extension") field = "extension";
      // if (key === "callstop") field = "callstart";

      if (keyObject?.constraints?.[0]?.value) {
        const match = keyObject.constraints[0].matchMode;
        let matchValue = getMatchValue(match);

        if (key === "created_at" || key === "updated_at") {
          matchValue = checkDatesMatchValue(keyObject.constraints);
        } else if (key === "callstart" || key === "callstop") {
          matchValue = "btw";
        }

        const filterObject = {
          field,
          value: [],
          match: matchValue,
        };

        // Push values
        for (const constraint of keyObject.constraints) {
          let value = constraint.value;

          if (
            key === "created_at" ||
            key === "updated_at"
            // key === "callstop" ||
            // key === "callstart"
          ) {
            value = formatDate(value);
          }

          if (key === "callstop" || key === "callstart") {
            value = formatDates(value);
          }

          if (field === "calltype" || field === "dialstatus") {
            filterObject.value.push(value?.code || value);
          } else if (field === "callstop" || field === "callstart") {
            filterObject.value = [value[0], value[1]];
          } else {
            filterObject.value.push(value);
          }
        }

        filters.push(filterObject);
      }
    }

    // console.log("filters", filters);

    // const callstopFilter = filters.find((i) => i.field === "callstop");

    // if (callstopFilter) {
    //   const callstopFilterValue = callstopFilter.value;
    //   const callstartFilter = filters.find((i) => i.field === "callstart");
    //   if (callstartFilter) {
    //     const callstartFilterValue = callstartFilter.value;
    //     callstartFilter.value = [
    //       callstartFilterValue[0],
    //       callstopFilterValue[0],
    //     ];
    //     filters.splice(filters.indexOf(callstopFilter), 1);
    //   }
    // }

    // Generate Sort values
    if (lazyParams.multiSortMeta?.length) {
      for (const item of lazyParams.multiSortMeta) {
        sort.push({
          field: item?.field,
          order: item?.order === 1 ? "asc" : "desc",
        });
      }
    }

    if (pagination.rows) {
      paging.page = +pagination.page + 1;
      paging.records = +pagination.rows;
    }

    return {
      filter: filters,
      sort,
      paging,
    };
  };

  const resetFilters = (filters, keyValue) => {
    const resetedFilters = { ...filters };
    for (const key in resetedFilters) {
      if (key === keyValue) {
        const existFilter = filters[key]?.constraints?.[0];

        if (existFilter) existFilter.value = null;
      }
    }

    return resetedFilters;
  };

  return {
    filters,
    getFilters,
    resetFilters,
  };
}
